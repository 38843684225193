
//
// buttons effects
// $background, $border, $hover-background, $hover-border, $active-background, $active-border

.btn-secondary{
@include button-variant($white,$gray-500,$primary,$primary,$primary,$primary);
}

.btn-primary {
@include button-variant($primary,$primary,$primary,$primary,$primary,$primary);
}


//
// navbar
.navbar-dark {
  .navbar-brand {
    

    @include hover-focus {
      
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;
      padding-left: 1rem;
      @include hover-focus {
        color: $gray-900;
          background-color: $navbar-dark-active-bg-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $primary;
        background-color: black;
    }
  }
    

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
}

//
// table
.table {
  thead th {
    background-color: $primary;
    color: #ffffff;
    border: none;
  }  
}


.table-bordered {
  thead {
    th,
    td {
        background-color: $primary;
        color: #ffffff;
        border: none;
    }
  }
}